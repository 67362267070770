import * as React from 'react'

import { NextPage } from '@grandvisionhq/next'
import { InitialProps, Page, Props } from '@grandvisionhq/www-next/pages/error'

import { layoutSettings, withCms } from '../cms'
import { defaultErrorProps } from '../components/error-props'
import { INTL_DEFAULTS } from '../configuration/intl'

const Error: NextPage<Props, InitialProps> = (props) => {
  const locale = props.locale || INTL_DEFAULTS.locale
  const localizedErrorProps = defaultErrorProps[locale]
  return <Page {...props} {...localizedErrorProps} layoutSettings={layoutSettings} />
}

Error.getInitialProps = Page.getInitialProps

export default withCms({ wrapInContainerPage: false })(Error)
